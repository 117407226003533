.PhoneInput {
	position: relative;
	&Country {
		width: 45px;
		margin-left: 18px;
	}
	&CountryIcon {
		max-width: 35px;
	}
	&Input {
		@extend %inp;
		font-size: 18px;
		padding: 14px 18px 13px 60px;
		margin-left: -68px;
		& + .inp-label {
			font-size: 18px;
		}
		&::placeholder {
			color: $colorGray;
			font-weight: normal;
		}

		// STATEs
		&:focus {
			&::placeholder {
				color: $colorGray;
			}
		}
		&:focus + .inp-label {
			@include placeholderLabel();
			span {
				display: none;
			}
		}
		&:not(:placeholder-shown) + .inp-label {
			@include placeholderLabel();
			span {
				display: none;
			}
		}
		/* autoprefixer: ignore next */
		&:not(:-ms-input-placeholder) + .inp-label {
			@include placeholderLabel();
		}
		&:focus + .inp-label {
			color: $colorPrimary;
		}
	}
}
