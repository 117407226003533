.header {
	position: sticky;
	top: 0;
	z-index: map-get($zindex, header);
	background: rgba($colorWhite, 0.97);
	transition: transform $t;
	border-bottom: 1px solid $colorBg;
	.row-main {
		height: 100%;
		display: flex;
		transition: transform $t;
	}
	&__logo {
		display: inline-block;
		font-size: 100%;
		line-height: 1;
		color: $colorBlack;
		margin: 0;
		padding: 0;
		svg {
			color: $colorBlack;
			width: auto;
		}
	}

	// MEDIA QUERIES
	@media ($lgDown) {
		padding-top: 71px;
		overflow: hidden;
		.row-main {
			height: 0;
			padding-bottom: 0;
			background-color: $colorPrimary;
			flex-direction: column;
			transition: height $t;
		}
		&__logo {
			margin-top: -71px;
			height: 71px;
			display: flex;
			align-items: center;
			flex-shrink: 0;
			transition: color $t;
			svg {
				height: 32px;
			}
		}

		// STATEs
		.menu-opened &__logo svg {
			color: $colorPrimary;
		}
		.menu-opened & {
			overflow: initial;
			.row-main {
				height: calc(100vh - 71px);
				padding: 0 $s1 $s1;
			}
		}
	}
	@media ($lgUp) {
		height: 102px;
		.row-main {
			align-items: center;
		}
		&.is-condensed {
			transform: translateY(-30px);
			.row-main {
				transform: translateY(15px);
			}
		}
		&.is-condensed &__logo svg {
			height: 36px;
		}
		&__logo {
			margin-right: auto;
			svg {
				transition: height $t;
			}
		}

		// STATEs
		.m-main.is-open &__logo {
			opacity: 1;
			transition: opacity $t;
		}
	}
}
