%inp {
	display: block;
	border: 1px solid $colorBdInp;
	padding: $s1 $s1 17px;
	background-color: $colorWhite;
	color: $colorText;
	font-size: 18px;
	line-height: (20/18);
	width: 100%;
	outline: none;
	border-radius: 0;
	appearance: none;
	transition: background-color $t, border-color $t;

	// STATEs
	&:disabled {
		background-color: rgba($color: $colorBg, $alpha: 0.6);
	}
	&:focus {
		border-color: $colorBd;
	}
	.has-error & {
		border-color: $colorRed;
	}
	.has-ok & {
		border-color: $colorSuccess;
	}
}
