.btn {
	display: inline-block;
	vertical-align: top;
	padding: 0;
	border: 0;
	background: none;
	text-decoration: none;
	font: inherit;
	cursor: pointer;
	&__text {
		position: relative;
		display: block;
		padding: 12px 20px 11px;
		background-color: $colorSecondary;
		color: $colorWhite;
		text-decoration: none;
		font-weight: bold;
		text-align: center;
		transition: background-color $t, border-color $t, color $t;
		text-transform: uppercase;
		.iconned {
			display: flex;
			justify-content: center;
			text-transform: none;
		}
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 19px;
			width: 16px;
			height: 16px;
			margin: -8px 0 0 -8px;
			border: 2px solid;
			border-radius: 8px;
			border-top-color: transparent;
			opacity: 0;
			transition: opacity $t;
		}
	}

	// Icons
	.icon-svg {
		margin-top: 1px;
	}
	.icon-svg--download svg {
		width: 16px;
		height: 14px;
	}
	.iconned .icon-svg.icon-svg--play {
		margin-right: 0;
	}
	.icon-svg--play svg {
		margin-left: 9px;
	}
	.icon-svg--phone svg {
		width: 23px;
		height: 23px;
	}

	// VARIANTs
	&--block {
		display: block;
		width: 100%;
	}
	&--sm &__text {
		padding: 17px $s1 16px;
	}
	&--play &__text {
		padding: $s1;
		max-width: 75px;
	}

	&--link &__text {
		border: 0;
		padding: 0;
		color: $colorLink;
		background-color: transparent;
		text-decoration: underline;
		text-transform: uppercase;
	}
	&--secondary &__text {
		padding: 17px $s2 16px;
		background: $colorBg;
		color: $colorPrimary;
	}

	// STATEs
	&:disabled,
	&.is-disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&.is-loading &__text {
		&::after {
			opacity: 1;
			animation: animation-rotate 0.8s infinite linear;
		}
	}
	&.is-loading .icon-svg {
		opacity: 0;
	}
	&.is-loading {
		pointer-events: none;
	}
	// HOVERs
	&:hover &__text {
		text-decoration: none;
	}
	&:hover &__text {
		background-color: darken($colorSecondary, 10%);
		color: $colorWhite;
	}
	&--link:hover &__text {
		background: none;
		color: $colorHover;
	}
	&--secondary:hover &__text {
		background: $colorPrimary;
		color: $colorBg;
	}

	// MEDIA QUERIES
	@media ($lgDown) {
		&.hide-mobile {
			display: none;
		}
	}
	@media ($mdDown) {
		&--play &__text {
			max-width: 60px;
		}
		.icon-svg--play svg {
			width: 16px;
			height: 16px;
		}
		.icon-svg--play svg {
			margin-top: -6px;
			margin-left: 6px;
		}
	}
	@media ($mdUp) {
		&__text {
			padding: 17px 40px 16px;
			font-size: 18px;
			line-height: 22px;
		}
		&--x-s6 &__text {
			padding-left: $s6;
			padding-right: $s6;
		}
	}
	@media ($lgUp) {
		&.hide-desktop {
			display: none;
		}
	}
}
