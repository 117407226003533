.popup {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba($colorPrimary, 0.8);
	height: 100vh;
	width: 100%;
	z-index: map-get($zindex, popup);
	transition: opacity $t, transform $t, visibility $t;
	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__content {
		padding: $s4;
		border-radius: 2px;
		position: relative;
		background: $colorWhite;
		max-height: 100%;
		overflow: auto;
	}
	&__close {
		position: absolute;
		top: $s1;
		right: $s1;
		height: 14px;
		width: 14px;
	}

	// VARIANTs
	&--hidden {
		opacity: 0;
		visibility: hidden;
		transform: scale(0);
	}
	&--maximized &__wrapper {
		height: 100%;
		width: 100%;
	}
	&--maximized &__content {
		padding: 0;
		line-height: 0;
	}
	&--maximized &__close {
		top: 0;
		right: 0;
		width: 44px;
		height: 44px;
		background-color: $colorRed;
	}

	&--maximized &__close .as-link {
		color: white;
		padding: 15px;
	}
	&--maximized video {
		max-height: 90vh;
		max-width: 80vw;
	}

	// MEDIA QUERIES
	@media ($lgUp) {
		&__content {
			min-width: 460px;
		}
	}
}

.is-popup-open {
	height: 100vh;
	overflow: hidden;
}
