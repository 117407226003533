.u-hide {
	@include generateBreakpoints() {
		display: none;
	}
}
.u-show {
	@include generateBreakpoints() {
		display: block;
	}
}
.js .u-js-hide {
	display: none;
}

.collapsible {
	transform: scaleY(1);
	transform-origin: top;
	transition: transform $t ease-out;
	&--collapsed {
		transform: scaleY(0);
	}
}
