.iconned {
	display: inline-flex;
	align-items: center;
	.icon-svg {
		margin-left: $s0;
		svg {
			display: block;
		}
	}
	&--before {
		flex-direction: row-reverse;
		.icon-svg {
			margin-left: 0;
			margin-right: $s0;
		}
	}
	&--bottom {
		display: flex;
		flex-direction: column;
		.icon-svg {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 5px;
			padding-top: $s0;
		}
	}

	.u-vhide + .icon-svg {
		margin: 0;
	}

	// VARIANTs
	&--size-m .icon-svg {
		margin-left: $s4;
	}

	&--size-m.iconned--before .icon-svg {
		margin-left: 0;
		margin-right: $s4;
	}
}
