.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	&__main {
		flex: 1 1 auto;
	}

	// MEDIA QUERIES
	@media ($lgDown) {
		// STATEs
		&.menu-opened {
			overflow: hidden;
			height: 100vh;
		}
	}
}
