.inp-item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding-left: 30px;
	cursor: pointer;
	line-height: $s1;

	&__inp {
		position: absolute;
		left: -5000px;
	}
	&__text {
		display: block;
		font-size: 14px;
		line-height: (18/14);
		border-radius: 2px;
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: $s1;
			height: $s1;
		}
		&::before {
			border: 1px solid $colorPrimary;
			background: $colorWhite;
			transition: border-color $t;
		}
		&::after {
			background: $colorPrimary;
			opacity: 0;
			transition: opacity $t;
		}
	}

	// STATES
	.has-error &__text {
		&::before {
			border-color: $colorRed;
		}
	}
	.has-ok &__text {
		&::before {
			border-color: $colorSuccess;
		}
	}

	&__inp:focus + &__text {
		&::before {
			border-color: $colorPrimary;
		}
	}

	&__inp:disabled + &__text {
		color: rgba($colorText, 0.5);
		cursor: default;
		&::after {
			background-color: $colorBg;
		}
	}

	&--radio &__inp:checked + &__text {
		&::after {
			background: $colorBd;
			width: 10px;
			height: 10px;
			top: 4px;
			left: 4px;
		}
	}
	// MEDIA QUERIES
	@media ($lgDown) {
		&--radio &__inp:checked + &__text {
			&::after {
				width: 13px;
				height: 13px;
				top: 5px;
				left: 5px;
			}
		}
	}

	&__inp:checked + &__text {
		&::after {
			opacity: 1;
			background-image: url($svgCheckbox);
			background-position: top 50% right 50%;
			background-repeat: no-repeat;
			background-size: 14px 14px;
		}
	}

	// VARIANTS & STATES
	&--radio {
		display: block;
	}
	&--radio &__text {
		&::before,
		&::after {
			border-radius: 50%;
			background-size: 9px 9px;
		}
	}
}
