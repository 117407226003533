.icon-svg {
	display: inline-block;
	vertical-align: top;
	svg {
		fill: currentColor;
		display: inline-block;
		vertical-align: top;
	}

	&--facebook svg {
		fill: $colorFacebook;
	}
	&--twitter svg {
		fill: $colorTwitter;
	}
	&--linkedIn svg {
		fill: $colorLinkedIn;
	}
}
