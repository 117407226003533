.main {
	padding: $s5 0;
	> :last-child {
		margin-bottom: 0;
	}

	// MQ
	@media ($lgUp) {
		padding: $s9 0;
	}
	// MODIF
	&--hp {
		padding: 0;
	}
}
