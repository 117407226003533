.m-footer {
	display: flex;
	text-align: center;

	&__items {
		border: 0 solid transparent;
		&:first-child {
			border: 0;
		}
	}
	&__list {
		@extend %reset-ul;
		position: relative;
		z-index: map-get($zindex, m-footer);
	}
	&__item {
		@extend %reset-ul-li;
		margin-bottom: $s0;
	}
	&__link {
		color: $colorBlack;
		text-decoration: none;
	}
	// MEDIA QUERIES
	@media ($mdDown) {
		flex-direction: column;
	}
	@media ($lgDown) {
		margin-bottom: -($s7);
		&__items {
			margin-bottom: $s7;
		}
		&__title {
			margin-bottom: $s6;
			font-size: 22px;
			line-height: (26/22);
		}
	}

	@media ($mdUp) {
		text-align: right;
		&__items {
			border-left-width: 75px;
		}
	}
	@media ($lgUp) {
		margin-top: 25px;
		&__title {
			margin-bottom: $s5;
			font-size: 16px;
			line-height: (19/16);
		}
	}
}
