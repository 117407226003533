html {
	box-sizing: border-box;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
body {
	position: relative;
	min-width: 320px;
	overflow-x: hidden;
	overflow-y: scroll;
}
:root {
	scroll-behavior: smooth;
}
:first-child {
	margin-top: 0;
}

.first-mb-0 {
	*:first-child {
		margin-top: 0 !important;
	}
}

.last-mb-0 {
	*:last-child {
		margin-bottom: 0 !important;
	}
}

@import 'page';
@import 'header';
@import 'footer';
@import 'main';
