.b-params {
	color: $colorPrimary;
	.b-table {
		margin-bottom: 0;
		table {
			td,
			th {
				border-color: $colorPrimary;
			}
		}
	}
	&__content,
	&__image {
		margin-bottom: $s5;
	}
	&__image {
		text-align: center;
		img {
			max-width: 100%;
			height: auto;
		}
	}

	// MQ
	@media ($lgUp) {
		margin-left: -$s5;
		margin-bottom: $s3;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__content,
		&__image {
			border: 0 solid transparent;
			border-width: 0 0 0 $s5;
		}
		&__image {
			flex: 0 1 auto;
			max-width: 50%;
		}
	}
	@media ($xlUp) {
		&__image {
			max-width: 40%;
		}
		.b-table table {
			td,
			th {
				min-width: 270px;
			}
		}
	}
}
.s-params {
	&__title {
		@extend .h3;
		color: $colorPrimary;
		margin-bottom: $s6;
		text-align: center;
	}
	margin: 0 0 $s9;
	padding-top: 94px;
	background: $colorGreen url($imgPath + 'bg/bubble.svg') 0 0 no-repeat;
	background-size: 1192px 164px;
}
