.s-error {
	background: $colorPrimary;
	height: calc(100vh - 102px);
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: $colorWhite;

	&__inner {
		max-width: 670px;
	}
	&__title {
		font-size: 86px;
		line-height: 103px;
		margin-bottom: $s6;
	}
	&__desc {
		font-size: 44px;
		line-height: 54px;
		font-weight: bold;
		margin-bottom: $s1;
		> * {
			margin: 0;
		}
	}
	&__text {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: $s2;
	}
	// MEDIA QUERIES
	@media ($lgDown) {
		background: $colorPrimary url($imgPath+'bg/bg-404sm.png') 50% 50% no-repeat;
		background-size: 375px 648px;
		padding: $s1;
		height: calc(100vh - 72px);
		&__title {
			font-size: 55px;
			line-height: 54px;
			margin-bottom: $s4;
		}
		&__desc {
			font-size: 35px;
			line-height: 45px;
			margin-bottom: $s2;
		}
		&__text {
			font-size: 16px;
			line-height: 26px;
			margin-bottom: $s4;
		}
	}
	@media ($lgUp) {
		background: $colorPrimary url($imgPath+'bg/bg-404.png') 50% 50% no-repeat;
		background-size: 1440px 800px;
	}
}
