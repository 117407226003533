.m-accessibility {
	position: absolute;
	left: -5000px;
	top: 0;
	a {
		&:focus,
		&:active {
			position: absolute;
			top: 0;
			left: 5000px;
			width: 200px;
			padding: 2px 0 5px;
			z-index: map-get($zindex, m-accessibility);
			text-align: center;
			background-color: $colorWhite;
		}
	}
}
