%states {
	.has-error & {
		color: $colorRed;
	}
	.has-ok & {
		color: $colorSuccess;
	}
}

.inp-text {
	@extend %inp;
	@at-root textarea#{&} {
		height: auto;
	}
	&::placeholder {
		color: $colorGray;
		font-weight: normal;
	}

	// STATEs
	&:focus {
		&::placeholder {
			color: $colorGray;
		}
	}
	&:focus + .inp-label {
		@include placeholderLabel();
		span {
			display: none;
		}
		@extend %states;
	}
	&:not(:placeholder-shown) + .inp-label {
		@include placeholderLabel();
		span {
			display: none;
		}
		@extend %states;
	}
	/* autoprefixer: ignore next */
	&:not(:-ms-input-placeholder) + .inp-label {
		@include placeholderLabel();
	}
	&:focus + .inp-label {
		color: $colorPrimary;
	}
	// STATEs
	@extend %states;
}
