.heading-separator {
	display: flex;
	text-align: center;
	&__text {
		width: 100%;
		max-width: 780px;
		padding: 0 $s4;
	}
	&:before,
	&:after {
		content: '';
		flex: 1 1 auto;
		background: url('/img/bg/line.svg') 0 0 repeat-x;
		height: 5px;
		margin-top: 0.6em;
	}
	.b-std & {
		margin: $s9 ($s4 * -1) $s4;
		&:first-child {
			margin-top: 0;
		}
	}

	// MEDIA QUERIES

	@media ($lgDown) {
		&__text {
			max-width: (540px + 2 * $s5);
		}
	}

	@media ($mdDown) {
		&__text {
			max-width: (270px + 2 * $s0);
			padding: 0 $s0;
		}

		.b-std & {
			margin: $s6 ($s1 * -1) $s3;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}
