@font-face {
	font-family: 'setup_grotesk_mts';
	src: url($fontsPath + 'setupgroteskmts-regular-webfont.woff2') format('woff2'),
		url($fontsPath + 'setupgroteskmts-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'setup_grotesk_mts';
	src: url($fontsPath + 'setupgroteskmts-bold-webfont.woff2') format('woff2'),
		url($fontsPath + 'setupgroteskmts-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'setup_grotesk_mts';
	src: url($fontsPath + 'setupgroteskmts-regular-hu-webfont.woff2') format('woff2'),
		url($fontsPath + 'setupgroteskmts-regular-hu-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'setup_grotesk_mts';
	src: url($fontsPath + 'setupgroteskmts-bold-hu-webfont.woff2') format('woff2'),
		url($fontsPath + 'setupgroteskmts-bold-hu-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
