html {
	font-family: $fontPrimary;
	font-size: $fontSize;
	line-height: $lineHeight;
	color: $colorText;
	@media ($xxlUp) {
		font-size: $fontSizeMd;
		line-height: $lineHeightMd;
	}
}

// Headings
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $fontSecondary;
	font-weight: 700;
	line-height: 1.2;
	margin: 1.5em 0 0.5em;
}
h1,
.h1 {
	font-size: ($s7 - 1px);

	@media ($lgDown) {
		font-size: $s6;
	}
	@media ($mdDown) {
		font-size: $s5;
	}
}
h2,
.h2 {
	font-size: $s5;
	line-height: (($s5 + 10px) / $s5);
	margin-top: $s9;
	margin-bottom: $s8;

	@media ($lgDown) {
		font-size: $s4;
		margin-top: $s8;
		margin-bottom: $s7;
	}
	@media ($mdDown) {
		font-size: $s3;
		margin-top: $s7;
		margin-bottom: $s6;
	}
}
h3,
.h3 {
	font-size: $s4;
	line-height: (($s4 + 7px) / $s4);
	margin-top: $s6;
	margin-bottom: $s5;
	@media ($lgDown) {
		font-size: round($s3 + ($s4 - $s3) / 2);
		margin-top: $s5;
		margin-bottom: $s4;
	}
	@media ($mdDown) {
		font-size: $s3;
		margin-top: $s4;
		margin-bottom: $s3;
	}
}
%h4 {
	font-size: $s3;
	line-height: (($s3 + 10px) / $s3);
	margin-top: $s6;
	margin-bottom: $s4;
	@media ($lgDown) {
		font-size: round($s2 + ($s3 - $s2) / 2);
		margin-top: $s5;
		margin-bottom: $s3;
	}
	@media ($mdDown) {
		font-size: ($s2 - 1px);
		margin-top: $s4;
		margin-bottom: $s2;
	}
}
h4,
.h4 {
	@extend %h4;
}
h5,
.h5 {
	font-size: $s2;
	line-height: (($s2 + 11px) / $s2);
	margin-top: $s5;
	margin-bottom: $s3;
	@media ($lgDown) {
		margin-top: $s4;
		margin-bottom: $s2;
	}
}
h6,
.h6 {
	font-size: $s1;
	line-height: (28px / $s1);
	margin-top: $s5;
	margin-bottom: $s3;

	@media ($lgDown) {
		font-size: 16px;
		line-height: (26/16);
		margin-top: $s4;
		margin-bottom: $s2;
	}
}

// Paragraph
p {
	margin-bottom: $typoSpaceVertical;
}
hr {
	border: solid $colorBd;
	border-width: 1px 0 0;
	margin: $s4 0;
	height: 1px;
	overflow: hidden;
}

// Blockquote
blockquote {
	margin: $s4 0 $typoSpaceVertical;
	background: $colorBg;
	padding: $s3;
	p {
		margin-bottom: 0;
	}
}

// Links
a,
.as-link {
	color: $colorLink;
	text-decoration: underline;
	transition: color $t;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
	&:hover {
		color: $colorHover;
	}
}

// Lists
ul,
ol,
dl {
	margin: 0 0 $typoSpaceVertical;
	list-style: none;
	padding: 0;
}
li {
	padding: 0 0 0 20px;
	margin: 0 0 10px;
	ul:first-child,
	ol:first-child {
		margin-top: 5px;
	}
}
ul {
	li {
		background-image: url($svgListItem);
		background-position: 2px 0.65em;
		background-repeat: no-repeat;
		background-size: 5px 5px;
	}
}
ol {
	counter-reset: item;
	font-size: $fontSize;
	li {
		position: relative;
		&::before {
			content: counter(item) '.';
			counter-increment: item;
			float: left;
			margin-left: -20px;
			color: $colorPrimary;
			font-weight: bold;
		}
	}
	ol {
		li {
			&::before {
				content: counter(item, lower-alpha) '.';
			}
		}
	}
	@media ($xxlUp) {
		font-size: $fontSizeMd;
		line-height: $lineHeightMd;
	}
}
dt {
	font-weight: bold;
	margin: 0;
}
dd {
	margin: 0 0 ($typoSpaceVertical / 2);
	padding: 0;
}

// Tables
table {
	font-size: $fontSize;
	line-height: $lineHeight;
	width: 100%;
	clear: both;
	margin: 0 0 $typoSpaceVertical;
	empty-cells: hide;
	border-collapse: separate;
	border-spacing: 30px 0;
	border: none;
	@media ($xxlUp) {
		font-size: $fontSizeMd;
		line-height: $lineHeightMd;
	}
}
caption {
	font-weight: bold;
	text-align: left;
	padding: 0 0 10px;
	caption-side: top;
}
td,
th {
	vertical-align: bottom;
	text-align: left;
	padding: ($s2 - 1px) 0 $s0;
	border-bottom: 1px solid $colorBg;
}
thead th {
	color: $colorPrimary;
	font-family: $fontSecondary;
	font-weight: 700;
	text-transform: uppercase;
}
tbody th {
	font-weight: 700;
}
td.width-as-content,
th.width-as-content {
	width: 1px;
	white-space: nowrap;
}

// Image
figure {
	margin-bottom: $s6;
}
figcaption {
	margin-top: 0.5em;
}

img {
	@media ($xlDown) {
		max-width: 100%;
		height: auto;
	}
}
// Form
form {
	line-height: $s1;
}
//
small {
	font-size: 8px;
}

// sepecial hightlight
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	strong {
		background: linear-gradient(to top, #c4d93f, #c4d93f 0.16em, rgba(#c4d93f, 0) 0.18em, rgba(#c4d93f, 0));
		background-position: 0 -0.17em;
	}
}
em {
	font-size: 22px;
	display: inline-block;
	line-height: (25/22);
	font-weight: 700;
	font-style: normal;
	margin-bottom: 8px;
}
