.s-half-box {
	margin-bottom: $s7;

	&__title {
		@extend .h3;
		text-align: center;
		margin-top: 0;
	}

	@media ($mdUp) {
		margin-bottom: $s9;
	}
}
