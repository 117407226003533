.u-pt {
	&-s1 {
		padding-top: $s1;
	}
	&-s1 {
		padding-top: $s1;
	}
	&-s2 {
		padding-top: $s2;
	}
	&-s3 {
		padding-top: $s3;
	}
	&-s4 {
		padding-top: $s4;
	}
	&-s5 {
		padding-top: $s5;
	}
	&-s6 {
		padding-top: $s6;
	}
	&-s7 {
		padding-top: $s7;
	}
	&-s8 {
		padding-top: $s8;
	}
	&-s9 {
		padding-top: $s9;
	}
	&-s10 {
		padding-top: $s10;
	}
	&-s11 {
		padding-top: $s11;
	}
	&-s12 {
		padding-top: $s12;
	}
}

.u-mb {
	&-0 {
		margin-bottom: 0;
	}
	&-s1 {
		margin-bottom: $s1;
	}
	&-s2 {
		margin-bottom: $s2;
	}
	&-s3 {
		margin-bottom: $s3;
	}
	&-s4 {
		margin-bottom: $s4;
	}
	&-s5 {
		margin-bottom: $s5;
	}
	&-s6 {
		margin-bottom: $s6;
	}
	&-s7 {
		margin-bottom: $s7;
	}
	&-s8 {
		margin-bottom: $s8;
	}
	&-s9 {
		margin-bottom: $s9;
	}
	&-s10 {
		margin-bottom: $s10;
	}
	&-s11 {
		margin-bottom: $s11;
	}
	&-s12 {
		margin-bottom: $s12;
	}
}
