.b-contact {
	margin-bottom: $s6;

	&__content {
		a {
			color: $colorBlack;
		}
		.grid {
			margin-top: $s5;
		}
		h2,
		h3 {
			@extend %h4;
			margin-top: 0;
			margin-bottom: $s2;
		}
		h3 {
			margin-top: $s5;
		}
		> :last-child {
			margin-bottom: 0;
		}
		.grid__cell > p {
			margin-bottom: $s0;
		}
	}
	&__form {
		.btn {
			padding-top: $s1;
		}
	}

	&__content + &__form {
		margin-top: $s6;
	}
	// MEDIA QUERIES
	@media ($lgDown) {
		&__map {
			margin-bottom: $s5;
		}
	}
	@media ($mdUp) {
		&__content {
			font-size: 18px;
			line-height: (28/18);
		}
		&__form {
			.btn {
				padding-top: $s3;
				text-align: center;
			}
		}
		&__content + &__form {
			margin-top: $s9;
		}
	}
	@media ($lgUp) {
		margin-bottom: $s8;

		&__inner {
			display: flex;
			margin-left: -95px;
			margin-bottom: -$s5;

			> * {
				flex: 1 1 auto;
				border: 0 solid transparent;
				border-width: 0 0 $s5 95px;
			}
		}
		&__content {
			max-width: (470px + 95px);
		}
		&__map {
			position: relative;
			.b-map {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: calc((1190px - 100vw) / 2);
			}
		}
	}
	@media ($lgUp) and (max-width: 1279.98px) {
		&__map .b-map {
			left: -$s6;
		}
	}
}
