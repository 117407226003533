.m-secondary {
	background: rgba($colorBg, 0.97);
	transition: transform $t, visibility $t, opacity $t;
	position: sticky;
	top: 72px;
	z-index: map-get($zindex, m-secondary);
	&__list {
		@extend %reset-ul;
		display: flex;
		align-items: center;
		margin-left: -$s2;
	}
	&__item {
		@extend %reset-ul-li;
		border: 0 solid transparent;
		border-left-width: $s2;
	}
	&__link {
		color: $colorGray;
		display: block;
		margin: 0;
		padding: 15px 0 14px;
		text-decoration: none;
	}

	// STATEs
	&__link.is-active,
	&__link:hover {
		text-decoration: none;
		color: $colorPrimary;
	}

	// MEDIA QUERIES
	@media ($lgDown) {
		font-size: 14px;
		padding-left: $s2;
		&::before,
		&::after {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			bottom: 0;
			width: $s2;
			background: linear-gradient(90deg, $colorBg 20%, rgba($colorBg, 0) 100%);
		}
		&::after {
			left: auto;
			right: 0;
			background: linear-gradient(270deg, $colorBg 20%, rgba($colorBg, 0) 100%);
		}
		&__list {
			overflow: hidden;
			overflow-x: auto;
			white-space: nowrap;
			justify-content: space-between;
		}
		&__item:last-child {
			border-right-width: $s2;
		}
	}
	@media ($mdDown) {
		&__link {
			padding: 10px 0 9px;
		}
	}
	@media ($lgUp) {
		top: 72px;
		opacity: 0;
		transform: translateY(-100%);
		margin-bottom: -53px;
		visibility: hidden;
		&__list {
			margin-left: -$s5;
			justify-content: center;
		}
		&__item {
			border-left-width: $s5;
		}

		&.is-condensed {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}
	}
}
