.b-cta {
	position: relative;
	&__inner {
		background: $colorBg url($imgPath + 'bg/cta.svg') 50% 50% no-repeat;
		background-size: cover;
		padding: $s8 $s8 $s3;
		text-align: center;
	}

	&__title {
		text-align: center;
	}
	&__desc {
		max-width: 700px;
		color: $colorGray;
		font-size: 18px;
		font-weight: 700;
		line-height: (28/18);
		margin: 0 auto 1.5em;
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $colorPrimary;
		font-weight: bold;
		margin-top: $s5;
		.btn {
			margin: 0 $s1;
			min-width: 255px;
		}
	}

	// MQ
	@media ($lgDown) {
		&__inner {
			padding: $s7 $s6;
		}
	}

	@media ($mdDown) {
		&__inner {
			padding: $s5 $s1;
		}
		&__title {
			max-width: 500px;
		}
		&__desc {
			max-width: 400px;
		}
		&__buttons {
			flex-direction: column;
			.btn {
				margin: $s0 0;
				width: 100%;
				min-width: auto;
			}
		}
	}

	@media ($mdUp) {
		padding: $s1;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			width: 24px;
			height: 41px;
			background: url($imgPath + 'bg/dots.svg') 0 0 no-repeat;
			top: 0;
			left: 0;
		}
		&__inner {
			min-height: 420px;
			&:before,
			&::after {
				content: '';
				position: absolute;
				z-index: -1;
			}
			&:before {
				width: 8px;
				height: 24px;
				background: url($imgPath + 'bg/dots-right.svg') 0 0 no-repeat;
				top: $s1;
				right: 0;
			}
			&:after {
				width: 774px;
				height: 41px;
				background: url($imgPath + 'bg/dots-bottom.svg') 0 0 no-repeat;
				bottom: 0;
				right: 0;
			}
		}
	}
}

.s-cta {
	margin-bottom: $s5;
	// MQ
	@media ($mdUp) {
		margin-bottom: $s7;
	}
	@media ($lgUp) {
		margin-bottom: $s9;
	}
}
