.s-intro {
	margin-bottom: $s9;
	&__heading {
		color: $colorPrimary;
		margin: 0 0 $s4;
		.heading-separator__text {
			max-width: (632px + 2 * $s7);
			padding: 0 $s6;
		}

		strong {
			background: $colorGreen;
			padding: 0 0.1em;
		}
	}

	&__desc {
		max-width: 370px;
		margin: 0 auto;
		text-align: center;
	}

	// MQ
	@media ($lgDown) {
		margin-bottom: $s7;
		&__heading {
			.heading-separator__text {
				max-width: (540px + 2 * $s5);
				padding: 0 $s5;
			}
		}
	}

	@media ($mdDown) {
		margin-bottom: $s5;
		&__heading {
			margin: 0 0 $s3;
			.heading-separator__text {
				max-width: (270px + 2 * $s0);
				padding: 0 $s0;
			}
		}
		&__desc {
			font-weight: bold;
		}
	}
}
