.link-btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px solid $colorPrimary;
	text-align: center;
	padding: ($s3 - 1px) $s3 ($s3 - 2px);
	transition: background-color $t;
	> .icon-svg--world {
		flex: 1 1 100%;
		margin: 0 auto ($s3 - 2px);
	}

	// states
	&:hover {
		background: lighten($colorPrimary, 65%);
	}

	@media ($lgDown) {
		padding-left: $s1;
		padding-right: $s1;
	}
}
