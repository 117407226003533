.s-hero {
	position: relative;
	margin-bottom: $s3;
	// MQ
	@media ($lgUp) {
		min-height: 420px;
		margin-bottom: $s6;
	}
}

.b-hero {
	position: relative;
	padding: $s3 0 0;
	&__image {
		display: none;
	}
	&__title {
		max-width: 880px;
		color: $colorPrimary;
		margin-bottom: $s3;
	}
	&__desc {
		max-width: 440px;
		margin-bottom: $s5;
		font-size: 18px;
		line-height: (28px / 18px);
	}
	&__buttons {
		margin: 0;
		.btn {
			width: 100%;
			max-width: 255px;
		}
	}
	// MQ
	@media ($lgDown) {
		&__desc {
			max-width: 340px;
			font-size: 16px;
			line-height: (26px / 16px);
		}
	}
	@media ($mdDown) {
		&__title {
			max-width: none;
			margin-bottom: $s5;
		}
		&__desc {
			max-width: none;
		}
		&__buttons {
			.btn {
				max-width: none;
			}
		}
	}
	@media ($lgUp) {
		padding: $s9 0;

		&__image {
			display: block;
			position: absolute;
			left: percentage(695/1440);
			right: 0;
			top: 0;
			bottom: 0;
			z-index: -1;
			overflow: hidden;
			&::before {
				z-index: 1;
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-image: linear-gradient(180deg, rgba($colorBlack, 0.0001) 81.11%, $colorWhite 100%),
					linear-gradient(268.19deg, rgba(#eeeeee, 0.0001) 26.26%, $colorWhite 101.25%);
			}
			.image {
				object-position: left;
			}
		}
	}
}
