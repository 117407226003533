.inp-fix {
	position: relative;
	display: block;

	// STATEs
	@extend %states;
	.has-warning & {
		color: $colorOrange;
	}
	.inp-label {
		color: $colorGray;
		margin: 0;
		top: 50%;
		left: 0;
		position: absolute;
		transform: translate(0, -50%);
		font-size: 18px;
		line-height: (20/18);
		padding: 0 $s1;
		transition: all $t;
		cursor: auto;
		width: 100%;
		display: inline-flex;
		span {
			font-size: 14px;
			line-height: (22/14);
			margin-left: auto;
		}
	}
	textarea + .inp-label {
		top: $s1;
		transform: none;
	}
	.inp-text:not(textarea)::placeholder {
		opacity: 0;
	}
	/* autoprefixer: ignore next */
	.inp-text:not(textarea):-ms-input-placeholder {
		opacity: 1;
	}
	//.inp--btn & .inp-text::placeholder,
	&.inp-icon .inp-text::placeholder {
		opacity: 1;
	}
}
