.b-table {
	margin-bottom: $s5;
	max-width: calc(870px + 60px);
	margin-left: auto;
	margin-right: auto;
	&__title {
		@extend .h3;
		color: $colorPrimary;
		margin-bottom: $s5;
		text-align: center;
	}
	&__paging {
		padding: $s1 $s3;
	}
	&__wrapper {
		position: relative;
		overflow-x: auto;
		table {
			min-width: 480px;
			margin-bottom: 0;
		}
	}
	&__summary {
		font-size: $fontSizeMd;
		font-weight: 700;
		th {
			color: $colorSecondary;
			text-transform: uppercase;
		}
	}

	// VARIANTs
	&--overview {
		margin-left: auto;
		margin-right: auto;
		max-width: calc(100vw - 36px);
		border: 1px solid $colorGreen;
		padding: $s3 0;
		background-color: $colorWhite;
		position: relative;
	}
	&--overview &__title {
		margin-bottom: $s4;
	}

	// MQ
	@media ($mdUp) {
		margin-bottom: $s7;
		&__title {
			margin-bottom: $s7;
		}
	}
	@media ($lgUp) {
		margin-bottom: $s9;
		&--overview {
			padding: $s5 $s1;
			max-width: 870px;
			padding: $s7 $s1;
			&::before,
			&::after {
				content: '';
				position: absolute;
				z-index: -1;
				top: 30px;
				left: -60px;
				width: 120px;
				height: 120px;
				background-size: cover;
				background: url($imgPath + 'bg/green-bubble.svg') 0 0 no-repeat;
			}
			&::after {
				bottom: 30px;
				top: auto;
				left: auto;
				right: -60px;
				height: 250px;
				background: url($imgPath + 'bg/blue-bubble.svg') 0 0 no-repeat;
			}
		}
	}
	@media ($xlUp) {
		tbody th {
			min-width: 270px;
		}
		&--overview {
			max-width: 970px;

			&::before,
			&::after {
				left: -130px;
				width: 261px;
				height: 261px;
			}
			&::after {
				bottom: 30px;
				left: auto;
				right: -130px;
				height: 546px;
				background: url($imgPath + 'bg/blue-bubble.svg') 0 0 no-repeat;
			}
		}
	}
}
