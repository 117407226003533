.m-lang {
	background-color: #eaeaea;
	font-size: $fontSizeMd;
	padding: $s0 0;
	line-height: (20px / $fontSizeMd);
	&__list {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: -($s0);
		margin-bottom: -($s0);
		@extend %reset-ul;
	}
	&__item {
		@extend %reset-ul-li;
		border: $s0 solid transparent;
		border-width: 0 0 $s0 $s0;
	}
	&__item + &__item {
		padding-left: $s0 + 4;
		position: relative;
		&::before {
			content: '|';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
		}
	}
	&__link {
		color: $colorBlack;
		text-decoration: none;
	}
}
