.inp-icon {
	.icon-svg {
		display: block;
		svg {
			width: auto;
			height: $s1;
		}
	}
	.inp-text,
	.inp-select {
		padding-left: $s6;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: $s1;
		font-size: 0;
		transform: translateY(-50%);
		&:not(button) {
			pointer-events: none;
		}
	}

	// STATES
	&--after {
		.inp-text,
		.inp-select {
			padding-right: $s6;
			padding-left: $s1;
		}
		.inp-icon__icon {
			right: 15px;
			left: auto;
		}
	}
}
