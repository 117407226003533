.m-sub {
	margin-top: $s1;
	padding: 3px $s1 6px;
	&__list {
		@extend %reset-ul;
	}
	&__item {
		@extend %reset-ul-li;
	}

	&__link {
		padding: 15px 10px 15px;
		margin: 0;
		position: relative;
		display: block;
		font-weight: bold;
		text-decoration: none;
		color: $colorBlack;
	}

	// STATEs
	.isOpen & {
		opacity: 1;
		visibility: visible;
	}
	&__link.is-active,
	&__link:hover {
		color: $colorGreen;
	}

	// MEDIA QUERIES
	@media ($lgDown) {
		font-size: 28px;
		line-height: (35/28);
		&__link {
			color: $colorWhite;
		}
	}

	@media ($lgUp) {
		background: $colorWhite;
		position: absolute;
		top: 26px;
		left: -12px;
		opacity: 0;
		transition: opacity $t;
		visibility: hidden;
		box-shadow: 0 0 22px 5px rgba(#858585, 0.15);
		z-index: map-get($zindex, header);
		&::before {
			content: '';
			position: absolute;
			top: -26px;
			left: 0;
			right: 0;
			height: 26px;
		}
		&::after {
			content: '';
			position: absolute;
			top: -8px;
			@include triangle('up', 12px, 8px, $color: $colorWhite);
			left: $s5;
		}
		&__item:last-child &__link {
			&::before {
				display: none;
			}
		}
		&__link {
			&::before {
				content: '';
				position: absolute;
				height: 1px;
				bottom: -1px;
				left: 0;
				right: 0;
				background-color: $colorBg;
			}
		}
	}
}
