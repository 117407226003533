.footer {
	font-size: 12px;
	line-height: (14/12);
	border-top: 1px solid $colorBg;

	.row-main {
		display: flex;
		flex-wrap: wrap;
	}
	&__logo {
		.icon-svg {
			margin-bottom: auto;
			color: $colorGray;
		}
		svg {
			width: 100px;
			fill: $colorGray;
		}
	}
	&__links {
		z-index: map-get($zindex, footer-links);
		margin-left: -11px;
		a {
			margin-left: 11px;
		}
	}
	// MEDIA QUERIES
	@media ($lgDown) {
		text-align: center;
		padding-top: $s6;
		padding-bottom: $s7;
		.row-main {
			align-items: center;
			flex-direction: column;
		}
		&__logo {
			margin-bottom: $s9;
		}
	}
	@media ($mdDown) {
		padding-top: $s3;
		padding-bottom: $s5;
		&__logo {
			margin-bottom: $s7;
		}
	}
	@media ($lgUp) {
		padding-top: $s7;
		padding-bottom: $s8;

		&__logo {
			margin-right: auto;
		}
		&__links {
			flex: 0 1 100%;
			margin-top: -24px;
		}
	}
	@media ($xxlUp) {
		font-size: $fontSize;
		line-height: $lineHeight;
	}
}
