.title {
	@extend %h4;
	position: relative;
	margin-top: $s2;
	margin-bottom: $s3;

	&:first-child {
		margin-top: 0;
	}

	// MQ
	@media ($smUp) {
		padding: 0 $s5;
		margin-top: $s6;
		margin-bottom: $s7;
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			bottom: 0;
			right: 0;
			left: 0;
			height: 56px;
			background-image: url($imgPath + 'bg/title.svg');
			background-position: 0 50%;
			background-size: 57px 30px;
			background-repeat: no-repeat;
		}
		&::after {
			background-image: url($imgPath + 'bg/title-right.svg');
			background-position: 100% 50%;
			background-size: 15px 30px;
			background-repeat: no-repeat;
		}
	}
	@media ($lgUp) {
		padding: 0 $s10;
		&::before {
			background-size: 107px 56px;
		}
		&::after {
			background-size: 28px 56px;
		}
	}
}
