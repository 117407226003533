.inp-select {
	@extend %inp;
	padding-right: 40px;
	background-image: url($svgSelect);
	background-position: top 50% right 15px;
	background-repeat: no-repeat;
	background-size: 12px 8px;
	color: $colorText;
	&::-ms-expand {
		display: none;
	}
	// STATEs
	&:disabled {
		color: $colorGray;
	}
	&:focus {
		color: $colorText;
	}

	&:focus + .inp-label {
		@include placeholderLabel();
		span {
			display: none;
		}
	}
	&:not(:placeholder-shown) + .inp-label {
		@include placeholderLabel();
		span {
			display: none;
		}
	}
	/* autoprefixer: ignore next */
	&:not(:-ms-input-placeholder) + .inp-label {
		@include placeholderLabel();
	}
	&:focus + .inp-label {
		color: $colorPrimary;
	}
}
