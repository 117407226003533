@import 'base/index';
@import 'core/index';
@import 'components/index';
@import 'layout/index';
@import 'utilities/index';

@media print {
	@import 'print/index';
	body {
		width: 100%;
		background: none;
		color: $colorBlack;
		font-family: $fontSystem;
		font-size: 8pt;
		line-height: 12pt;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: 0;
	}

	p,
	ul,
	table {
		margin: 0 0 0.4cm;
	}

	a {
		color: $colorBlack;
		text-decoration: none;
	}

	img {
		max-width: 100%;
		height: auto;
		border: 0;
	}
}
