// Colors
$colorPrimary: #0a319e;
$colorSecondary: #ff4b27;

$colorBlack: #000;
$colorWhite: #fff;
$colorRed: #ff4b27;
$colorGreen: #c4d93f;
$colorSuccess: #4db72e;
$colorOrange: #ff9018;
$colorGray: #5f6371;

$colorText: $colorBlack;
$colorBd: #0a319e;
$colorBg: #dee3f1;
$colorBdInp: #a4b3db;
$colorLink: $colorSecondary;
$colorHover: $colorPrimary;
$colorBs: #848484;

$colorFacebook: #3b5998;
$colorTwitter: #1da1f2;
$colorGoogle: #dd4b39;
$colorYoutube: #ff0000;
$colorLinkedIn: #0077b5;
$colorInstagram: #c13584;
$colorPinterest: #bd081c;

// Sizes
$s12: 210px;
$s11: 168px;
$s10: 134px;
$s9: 107px;
$s8: 86px;
$s7: 69px;
$s6: 55px;
$s5: 44px;
$s4: 35px;
$s3: 28px;
$s2: 23px;
$s1: 18px;
$s0: 10px;

// Font
$fontSystem: 'setup_grotesk_mts', sans-serif;
$fontPrimary: $fontSystem;
$fontSecondary: 'setup_grotesk_mts', sans-serif;
$fontSize: 14px;
$fontSizeMd: 16px;
$lineHeight: 24px / $fontSize;
$lineHeightMd: 26px / $fontSizeMd;

// Typography
$typoSpaceVertical: 1.5em;

// Grid
$gridColumns: 12;
$gridGutter: $s1;
$rowMainWidth: 1170px + (2 * $s6);
$rowMainXlWidth: 1440px;
$rowMainGutter: $s6;
$rowMainXlGutter: $s8;

// Paths
$imgPath: '/img/';
$fontsPath: '/fonts/';

// Inline json variables
$webkit: '(-webkit-min-device-pixel-ratio: 0)';
$retina: '(-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)';

$breakpoints: (
	'sm': '480px',
	'md': '750px',
	'lg': '1000px',
	'xl': '1346px',
);

$smUp: 'min-width: 480px';
$smDown: 'max-width: 479.98px';
$mdUp: 'min-width: 750px';
$mdDown: 'max-width: 749.98px';
$lgUp: 'min-width: 1000px';
$lgDown: 'max-width: 999.98px';
$xlUp: 'min-width: 1346px';
$xlDown: 'max-width: 1345.98px';

$lgSpecialDown: 'max-width: 1199.98px';
$lgSpecialUp: 'min-width: 1200px';
$xxlUp: 'min-width: 1440px';

$breakpointsVars: (
	'smUp': $smUp,
	'smDown': $smDown,
	'mdUp': $mdUp,
	'mdDown': $mdDown,
	'lgUp': $lgUp,
	'lgDown': $lgDown,
	'xlUp': $xlUp,
	'xlDown': $xlDown,
);

// Transitions
$t: 0.3s;

// Z-indexes
// pouziti - z-index: map-get($zindex, header);
$zindex: (
	header: 100,
	//logo: 10,
	//footer: 90,
	loader: 100,
	popup: 1000,
	footer-links: 9,
	m-main: 110,
	m-main-tool: 101,
	m-secondary: 90,
	m-footer: 10,
	m-accessibility: 900,
);

// SVGs
$svgPrimaryColor: '#{$colorPrimary}';
// prettier-ignore
$svgBullet: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath d='M6 0c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6z'/%3E%3C/svg%3E";
$svgListItem: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 5'%3E%3Cpath fill='#{svg($svgPrimaryColor)}' d='M0 0h5v5H0z'/%3E%3C/svg%3E%0A";
$svgSelect: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7.41'%3E%3Cpath d='M1.41 0L6 4.58 10.59 0 12 1.41l-6 6-6-6z' fill='%230a319e'/%3E%3C/svg%3E";
$svgCheckbox: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath d='M5 12.2l-5-5 1.4-1.4L5 9.4l7.6-7.6L14 3.2l-9 9z' fill='%23fff'/%3E%3C/svg%3E";
