.b-tool {
	position: relative;
	padding: $s3 $s2;
	min-height: 100%;
	box-shadow: 0px 5px 9px 3px rgba($colorBs, 0.08);
	display: flex;
	flex-direction: column;

	.btn {
		margin-top: auto;
	}
	&__heading {
		margin-bottom: $s5;
	}
	&__title {
		font-size: 18px;
		line-height: (24/18);
		color: $colorPrimary;
		text-transform: uppercase;
		margin-bottom: $s2;
	}
	&__desc {
		margin-bottom: $s2;
		*:last-child {
			margin-bottom: 0;
		}
	}
	&__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}

	// MODIF
	&--has-filter &__image {
		opacity: 0.8;
		overflow: hidden;
		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			top: -60%;
			right: -50%;
			bottom: 0;
			left: 0;
			background: radial-gradient(circle, rgba($colorWhite, 0.5) 0, rgba($colorWhite, 1) 60%);
		}
	}

	// MQ
	@media ($mdUp) {
		padding: $s5 $s4;
		line-height: (28px / $s1);
		font-size: $s1;

		&__title {
			font-size: 22px;
			line-height: (28/22);
			margin-bottom: $s5;
		}
		&__heading {
			margin-bottom: $s7;
		}
	}
	@media ($lgUp) {
		&__heading {
			margin-bottom: $s9;
		}
	}
}

.s-tool {
	margin-bottom: $s7;

	// MQ
	@media ($mdUp) {
		margin-bottom: $s9;
	}
	@media ($lgUp) {
		margin-bottom: $s11;
	}
}

.c-tool {
	position: relative;
	padding: 0 0 1px;

	.grid {
		margin-left: -30px;
		margin-bottom: -30px;
	}
	.grid__cell {
		border-left-width: 30px;
		border-bottom-width: 30px;
	}
}
