.item-icon {
	$s: &;

	display: inline-flex;
	align-items: center;
	&__icon {
		flex: 0 0 auto;
		margin: 0 5px 0 0;
	}
	&__text {
		flex: 1 1 auto;
	}

	// VARIANTS
	&--after {
		flex-direction: row-reverse;
		#{$s} {
			&__icon {
				margin: 0 0 0 5px;
			}
		}
	}
}
