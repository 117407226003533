.b-video {
	position: relative;
	margin: 0 auto;
	&__holder {
		position: relative;
		&:before {
			content: '';
			display: block;
			padding-top: percentage(474 / 843);
		}
	}
}

.s-video {
	margin-bottom: $s9;
	&__heading {
		font-size: $fontSize;
		text-transform: uppercase;
		margin: 0 0 $s4;
		color: $colorGreen;
	}

	@media ($mdDown) {
		margin-bottom: $s6;
		&__heading {
			margin: 0 0 $s2;
		}
		.row-main {
			padding: 0;
		}
	}
	@media ($xxlUp) {
		&__heading {
			font-size: $fontSizeMd;
			line-height: $lineHeightMd;
		}
	}
}

.is-cover {
	object-fit: cover;
}

.contain {
	object-fit: contain;
}

.fullsize {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-controls {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	visibility: hidden;
	&.has-overlay:before {
		content: '';
		pointer-events: auto;
		opacity: 0.5;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $colorPrimary;
		visibility: visible;
		pointer-events: none;
		transition: opacity $t;
	}
	> * {
		visibility: visible;
	}

	.btn {
		position: relative;
		height: 100%;
		width: 100%;
		&__text {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&--left {
		justify-content: flex-start;
	}
	&--right {
		justify-content: flex-end;
	}
	&--top {
		align-items: flex-start;
	}
	&--bottom {
		align-items: flex-end;
	}
}
