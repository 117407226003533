.b-map {
	position: relative;
	background: $colorBg;
	&::before {
		content: '';
		display: block;
		padding-top: percentage(620/970);
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}
