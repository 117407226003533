.message {
	font-size: $fontSize;
	line-height: $lineHeight;
	margin: 0 0 $s3;
	> :last-child {
		margin-bottom: 0;
	}

	.iconned--before {
		align-items: baseline;
	}

	.icon-svg {
		width: $s1;
		height: $s1;
		flex-shrink: 0;
		position: relative;
		top: 0.3em;
		svg {
			width: 100%;
			height: 100%;
		}
	}

	// VARIANTs
	&--bordered {
		padding: ($s0 + 5px) $s1;
		border: 1px solid $colorBg;
	}
	&--bordered.u-color-error {
		border-color: $colorRed;
	}
	&--bordered.u-color-success {
		border-color: $colorSuccess;
	}
	&--bordered.u-color-warning {
		border-color: $colorOrange;
	}

	&--big {
		text-align: center;
		max-width: 470px;
		margin: 0 auto $s6;
		font-size: 18px;
		line-height: (32px / 18px);
	}
	&--big .iconned--before {
		flex-direction: column-reverse;
		align-items: center;
	}
	&--big .icon-svg {
		margin: 0 0 $s4;
		width: $s7;
		height: $s7;
	}
	&--big .iconned__text {
		color: $colorText;
	}

	// MQ
	@media ($xxlUp) {
		font-size: $fontSizeMd;
		line-height: $lineHeightMd;
	}
}
