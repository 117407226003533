.b-teaser {
	position: relative;
	&__heading {
		@extend .h4;
		margin-bottom: $s3;
	}
	&__content {
		padding: $s3 0;
	}
	// MQ
	@media ($lgDown) {
		&__video {
			margin-bottom: $s3;
		}
	}
	@media ($lgUp) {
		display: flex;
		margin-left: -$s3;

		align-items: center;
		&__heading {
			margin-bottom: $s5;
		}
		&__content,
		&__video {
			flex: 1 0 50%;
			border: 0 solid transparent;
			border-width: 0 0 0 $s3;
		}
	}
	@media (min-width: 1280px) {
		margin-left: -($s1 + $s5);
		&__content,
		&__video {
			flex: 1 0 100%;
			border: 0 solid transparent;
			border-width: 0 0 0 ($s1 + $s5);
		}
		&__video {
			max-width: calc(100vw - 400px - 64px - (100vw - 1280px) / 2);
		}
		&__content {
			padding: $s6 0;
			max-width: 400px + ($s1 + $s5);
		}
	}
	@media ($lgUp) and (max-width: 1279.98px) {
		&__video {
			margin-right: -$s6;
		}
	}
}
.s-teaser {
	background: $colorPrimary;
	padding: $s7 0;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		right: 0;
		top: 6px;
		bottom: 0;
		opacity: 0.75;
		background: url($imgPath + 'bg/lines.svg') 0 0 repeat;
		background-size: 162px 25px;
	}
	&__wrapper {
		background: $colorWhite;
		position: relative;
		z-index: auto;
	}
	// MQ
	@media ($lgUp) {
		padding: $s10 0;
	}
}
