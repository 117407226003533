.row-main {
	@include clearfix();
	position: relative;
	margin: 0 auto;
	padding: 0 $rowMainGutter;
	max-width: $rowMainWidth;
	@media ($lgDown) {
		padding: 0 $s3;
	}
	@media ($mdDown) {
		padding: 0 $s1;
		max-width: 600px;
	}
	&--xl {
		@media ($lgUp) {
			max-width: $rowMainXlWidth;
			padding: 0 $rowMainXlGutter;
		}
	}
}
.grid {
	@extend %reset-ol;
	@extend %grid;
	margin-left: -($gridGutter);
	margin-bottom: -($gridGutter);
	&__cell {
		@extend %reset-ol-li;
		@extend %grid__cell;
		position: relative;
		border: $gridGutter solid transparent;
		// hide the border in MS high contrast mode
		border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E");
		border-width: 0 0 $gridGutter $gridGutter;
		&--top {
			align-self: flex-start;
		}
		&--middle {
			align-self: center;
		}
		&--bottom {
			align-self: flex-end;
		}
		&--eq {
			display: flex;
			> * {
				flex: 1 1 auto;
			}
		}
	}

	// VARIANTs
	&--scroll {
		@extend %grid--scroll;
	}
	&--nowrap {
		flex-wrap: nowrap;
	}
	&--middle {
		align-items: center;
		@include no-flex() {
			.grid__cell {
				vertical-align: middle;
			}
		}
	}
	&--bottom {
		align-items: flex-end;
		@include no-flex() {
			.grid__cell {
				vertical-align: bottom;
			}
		}
	}
	&--center {
		justify-content: center;
		@include no-flex() {
			.grid__cell {
				text-align: center;
			}
		}
	}
	&--right {
		justify-content: flex-end;
		@include no-flex() {
			.grid__cell {
				text-align: right;
			}
		}
	}
	&--space-between {
		justify-content: space-between;
	}

	// horizontal gutter spacing
	&--x-0 {
		margin-left: 0;
	}
	&--x-0 > &__cell {
		border-left-width: 0;
	}

	&--x-sm {
		margin-left: -($s0);
	}
	&--x-sm > &__cell {
		border-left-width: ($s0);
	}

	&--x-md {
		margin-left: -($s6);
	}
	&--x-md > &__cell {
		border-left-width: ($s6);
	}

	&--x-lg {
		margin-left: -($s5 + $s4);
	}
	&--x-lg > &__cell {
		border-left-width: ($s5 + $s4);
	}

	// vertical gutter spacing
	&--y-0 {
		margin-bottom: 0;
	}
	&--y-0 > &__cell {
		border-bottom-width: 0;
	}

	&--y-sm {
		margin-bottom: -($s0);
	}
	&--y-sm > &__cell {
		border-bottom-width: ($s0);
	}

	&--y-md {
		margin-bottom: -($s6);
	}
	&--y-md > &__cell {
		border-bottom-width: ($s6);
	}

	&--y-lg {
		margin-bottom: -($s5 + $s4);
	}
	&--y-lg > &__cell {
		border-bottom-width: ($s5 + $s4);
	}
}
.size {
	@include generateGridSize();

	@media ($lgSpecialUp) {
		&--side {
			flex-basis: percentage(541/1236);
			max-width: percentage(541/1236);
		}
		&--content {
			flex-basis: percentage(695/1236);
			max-width: percentage(695/1236);
		}
	}
}
// .push {
// 	@include generateGridPush();
// }
// .pull {
// 	@include generateGridPull();
// }
// .order {
// 	@include generateGridOrder();
// }
