.header {
	position: relative;
	margin: 0 0 10pt;
	padding: 0 0 10pt;
	border-bottom: 2pt solid $colorBlack;
	&__logo {
		margin: 0;
		padding: 0;
		font-weight: normal;
		font-size: 100%;
		line-height: 1;
		color: $colorBlack;

		img {
			border: 0;
		}
		svg {
			color: $colorBlack;
		}
	}
}
.footer {
	margin: 10pt 0 0;
	padding: 20pt 0 0;
	border-top: 2pt solid $colorBlack;
}
