.s-about {
	position: relative;
	box-shadow: 0px 5px 9px 3px rgba($colorBs, 0.08);
	background-color: $colorWhite;
	&__content {
		padding: $s1;
		& > :last-child {
			margin-bottom: 0;
		}
	}
	&__title {
		font-size: 22px;
		line-height: (28/22);
		text-transform: uppercase;
		color: $colorPrimary;
		margin-bottom: $s2;
	}
	// MEDIA QUERIES
	@media ($lgDown) {
		&__image {
			.image {
				width: 100%;
				height: auto;
			}
		}
	}
	@media ($mdUp) {
		display: flex;
		flex-wrap: wrap;
		> * {
			flex: 1 1 100%;
		}
		&__content {
			padding: $s3;
		}
		&__image {
			position: relative;
			display: block;
			overflow: hidden;
			max-width: percentage(7/12);
			&::before {
				padding-top: 100%;
				content: '';
				display: block;
			}
			.image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			&--right {
				max-width: percentage(5/12);
			}
		}
	}

	@media ($lgUp) {
		flex-wrap: nowrap;
		&__content {
			max-width: percentage(380 / 1440);
		}
		&__image {
			&--right {
				max-width: percentage(500/1440);
			}
		}
	}
	@media ($xlUp) {
		&__content {
			padding: 49px $s6;
		}
	}
	@media ($mdUp) and ($lgDown) {
		&__content {
			order: 1;
		}
		&__image {
			order: 2;
			&--right {
				order: 3;
			}
		}
	}
}
