.b-half-box {
	$s: &;
	display: flex;
	&__side {
		text-align: center;
	}

	&__side-holder {
		position: relative;
		&:before {
			content: '';
			display: block;
			padding-top: (500 / 705 * 100%);
		}
		.image {
			position: absolute;
			top: 0;
			max-width: 100%;
			max-height: 100%;
			height: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&__content {
		.text-block {
			h2,
			h3 {
				@extend .h3;
				margin-top: 0;
			}
		}
	}
	& + & {
		margin-top: $s9;
	}

	.btn {
		&__text {
			left: 0;
			top: auto;
			bottom: 0;
			transform: none;
		}
	}

	// VARIANTs
	&--annot &__side-holder {
		&:before {
			padding-top: (325 / 570 * 100%);
		}
	}
	&--reverse {
		flex-direction: row-reverse;

		.btn .btn__text {
			right: 0;
			left: auto;
		}
		&#{$s}--annot #{$s}__side-holder {
			margin-left: auto;
		}
	}

	// down
	@media ($mdDown) {
		flex-direction: column;
		box-shadow: 0 5px 9px 3px rgba($colorBs, 0.15);
		&__content {
			padding: $s3 $s1;
			h2 {
				margin-bottom: $s4;
			}
		}

		& + & {
			margin-top: $s4;
		}
	}

	// up
	@media ($mdUp) {
		align-items: center;
		justify-content: flex-end;

		&__content {
			flex: 0 0 auto;
			width: 50%;
			max-width: (435px + $s3 * 2);
			padding: 0 $s3;
		}

		&__side {
			flex: 0 0 auto;
			width: 50%;
			// max-width: 705px;
		}

		&--annot &__content {
			font-size: 18px;
			line-height: (28/18);
		}
		&--annot &__side-holder {
			max-width: 570px;
			margin-left: 15px;
		}
	}

	@media ($lgUp) {
		max-width: $rowMainXlWidth;
		margin-left: auto;
		margin-right: auto;
		&__content {
			padding: 0 $s6;
		}
		&--annot &__content {
			padding-left: 93px;
			max-width: 578px;
		}
	}

	@media ($xxlUp) {
		&--annot &__content {
			font-size: 20px;
			line-height: (30/20);
		}
	}
}
