.b-bar {
	padding: $s3 0;
	&__title {
		@extend %h4;
		color: $colorPrimary;
		margin-bottom: 0;
	}
	&__desc {
		font-weight: 700;
	}
	&__button {
		margin: 0;
	}
	// MQ
	@media ($mdDown) {
		&__content {
			margin-bottom: $s1;
		}
	}
	@media ($mdUp) {
		padding: $s5 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: -$s3;
		margin-bottom: -$s3;
		> * {
			border: 0 solid transparent;
			border-width: 0 0 $s3 $s3;
		}
		&__desc {
			font-size: 18px;
			line-height: (28/18);
			font-weight: 700;
		}
	}
}
.s-bar {
	margin-bottom: $s9;
	background: $colorGreen url($imgPath + 'bg/bubble.svg') 0 0 no-repeat;
	background-size: 630px 87px;
	.s-reason + & {
		margin-top: -($s3 + $s0);
	}
}
