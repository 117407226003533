.u-block-center {
	margin-left: auto;
	margin-right: auto;
}
.u-max-780 {
	max-width: 780px;
}
.u-max-870 {
	max-width: 870px;
}
.u-max-970 {
	max-width: 970px;
}
