.b-reason {
	position: relative;

	&__button {
		margin-top: $s6;
		margin-bottom: 0;
	}
	&__content {
		h2,
		h3 {
			@extend %h4;
			line-height: (34/28);
			color: $colorPrimary;
			margin-top: 0;
			margin-bottom: $s4;
		}
		.image {
			max-width: 100%;
			max-height: 100%;
		}
	}
	&__image {
		position: relative;
		margin-bottom: $s2;
		&::before {
			content: '';
			display: block;
			padding-top: percentage(200/350);
		}
		.image {
			border: 1px solid $colorGreen;
			margin-bottom: $s2;
		}
	}

	// MODIF
	&--dots {
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			width: 24px;
			height: 41px;
			background: url($imgPath + 'bg/dots.svg') 0 0 no-repeat;
			top: 90px;
			left: 0;
		}
	}
	&--dots &__content {
		text-align: center;
		strong {
			display: block;
			color: $colorRed;
			font-size: 20px;
			line-height: (24/20);
		}
	}

	&--dots &__image {
		margin-bottom: 0;
		display: flex;
		justify-content: center;
		&::before {
			padding-top: 0;
		}
		.image {
			max-height: 100px;
			border: none;
		}
	}
	&--dots &__inner {
		padding: $s3 $s1;

		&:before,
		&::after {
			content: '';
			position: absolute;
			z-index: -1;
		}
		&:before {
			width: 8px;
			height: 24px;
			background: url($imgPath + 'bg/dots-right.svg') 0 0 no-repeat;
			top: 108px;
			right: 0;
		}
		&:after {
			width: 774px;
			height: 41px;
			background: url($imgPath + 'bg/dots-bottom.svg') 0 0 no-repeat;
			bottom: 0;
			right: 0;
		}
	}

	&--sm {
		.image {
			border: none;
		}
	}
	&--sm &__content {
		h2,
		h3 {
			@extend .h6;
			text-transform: uppercase;
			margin-top: 0;
		}
	}

	// MQ
	@media ($lgUp) {
		&--dots &__content {
			font-size: 20px;
			line-height: (24/20);
			strong {
				font-size: 24px;
				line-height: (28/24);
			}
		}
		&--dots &__inner {
			padding: 63px $s3;
		}
	}
}

.s-reason {
	margin-bottom: $s9;
	&__heading {
		@extend .h3;
		max-width: $rowMainWidth;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $rowMainGutter;
		margin-bottom: $s9;
	}
	&--spacing-sm {
		.s-reason__heading {
			margin-bottom: 0;
		}
	}
	&__heading + .b-reason--sm {
		margin-top: -$s4;
	}
	&__heading + .b-reason--dots {
		margin-top: -21px;
	}

	// MQ
	@media ($mdDown) {
		margin-bottom: $s5;
		&__heading {
			margin-bottom: $s5;
		}
	}
}
