%reset-ul {
	margin: 0;
}

%reset-ul-li {
	margin: 0;
	padding: 0;
	background: none;
}

%reset-ol {
	@extend %reset-ul;
	counter-reset: none;
}

%reset-ol-li {
	@extend %reset-ul-li;
	position: static;
	&::before {
		content: normal;
		counter-increment: none;
		float: none;
		margin: 0;
	}
}

%grid {
	display: flex;
	flex-wrap: wrap;
	@include no-flex() {
		font-size: 0;
		@media #{$webkit} {
			letter-spacing: -1px;
		}
	}
}

%grid__cell {
	flex: 1 1 100%;
	max-width: 100%;
	@include no-flex() {
		display: inline-block;
		vertical-align: top;
		font-size: 1rem;
		letter-spacing: normal;
		white-space: normal;
	}
}

%grid--scroll {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	overflow-x: auto;
	.js & {
		overflow-x: hidden;
	}
	.grid__cell {
		flex-shrink: 0;
	}
	.slick-slide {
		.grid__cell {
			max-width: none;
		}
	}
	@include no-flex() {
		white-space: nowrap;
	}
}
