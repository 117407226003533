.b-std {
	position: relative;
	border: 2px solid $colorBg;
	margin-bottom: $s9;
	padding: $s4 - 2;
	& > :last-child {
		margin-bottom: 0;
	}
	&__title {
		@extend .h4;
		color: $colorPrimary;
	}
	// MQ
	@media ($mdDown) {
		margin-bottom: $s7;
	}
}
