.loader {
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	background: rgba($colorPrimary, 0.15);
	transform: translate(-50%, -50%);
	z-index: map-get($zindex, loader);
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 40px;
		height: 40px;
		margin: -20px 0 0 -20px;
		border: 4px solid $colorWhite;
		border-radius: 20px;
		border-top-color: transparent;
		animation: animation-rotate 0.8s infinite linear;
	}
}

.block-loader {
	position: relative;
	.loader {
		visibility: hidden;
		opacity: 0;
		transition: opacity $t 0s, visibility $t 0s;
	}

	// STATES
	&.is-loading {
		pointer-events: none;
	}
	&.is-loading .loader {
		visibility: visible;
		opacity: 1;
		transition-delay: 0s, 0s;
	}
}

.icon-svg.icon-svg--loader {
	animation: animation-rotate 0.8s infinite linear;
	svg {
		display: block;
	}
}
