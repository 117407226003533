.inp {
	margin: 0 0 $s0;

	// VARIANTs
	&--password {
		position: relative;
	}
	&--password &-text {
		padding-right: 61 + $s4 + $s1;
	}
	&--password &-fix::before {
		content: '';
		position: absolute;
		height: 2px;
		left: 0;
		bottom: 0;
		width: 0;
		transition: width $t;
	}
	&--password .u-color + &-fix::before {
		background-color: $colorPrimary;
		width: 60%;
	}
	&--password .u-color-short + &-fix::before {
		background-color: $colorRed;
		width: 20%;
	}
	&--password .u-color-weak + &-fix::before {
		background-color: $colorOrange;
		width: 40%;
	}
	&--password .u-color-good + &-fix::before {
		width: 80%;
	}
	&--password .u-color-strong + &-fix::before {
		background-color: $colorSuccess;
		width: 100%;
	}
	&--password .u-color {
		position: absolute;
		top: 1px;
		right: $s4;
		line-height: 46px;
		z-index: 1;
	}
	// STATEs
	&.has-error {
		color: $colorRed;
	}
	&.has-ok {
		color: $colorSuccess;
	}

	@media ($mdUp) {
		// VARIANTs
		&--btn {
			margin-bottom: 0;
		}
		&--btn &-text {
			border-right-width: 0;
			border-radius: 2px 0 0 2px;
		}

		&--btn .btn {
			border: 1px solid $colorPrimary;
			border-left-width: 0;
			border-radius: 0 2px 2px 0;
		}
	}
}
