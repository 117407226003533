.m-main {
	$s: &;

	display: flex;
	&__list {
		@extend %reset-ul;
	}
	&__item {
		@extend %reset-ul-li;
		&--submenu {
			position: relative;
		}
	}
	&__link {
		display: block;
		margin: 0;
		font-weight: bold;
		text-decoration: none;
	}

	// separator
	&__item--separator {
		background-image: url($svgBullet);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 12px;
		width: 12px;
	}

	// Submenu
	&__item--submenu {
		#{$s}__item {
			margin: 0;
		}
		&:hover .m-sub {
			opacity: 1;
			visibility: visible;
		}
	}

	// STATEs
	&__link.is-active,
	&__link:hover {
		color: $colorGreen;
		text-decoration: none;
	}

	// MEDIA QUERIES
	@media ($lgDown) {
		padding: $s5 0 $s1;
		margin-bottom: $s5;
		overflow: hidden;
		overflow-y: auto;
		&__tool {
			position: absolute;
			padding: 9px;
			top: -44px;
			right: 24px;
			z-index: map-get($zindex, m-main-tool);
			&-icon {
				position: absolute;
				top: 50%;
				left: 0;
				margin: -12px 0 0 0;
				width: 25px;
				height: 25px;
				transform: translateY(-50%);
				&::before,
				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					height: 2px;
					margin-top: -1px;
					background: $colorGreen;
					box-shadow: 0 -8px 0 $colorGreen, 0 8px 0 $colorGreen;
					transition: box-shadow $t $t, transform $t;
				}
			}
		}
		&__list {
			font-size: 35px;
			line-height: (42/35);
		}
		&__item {
			margin-bottom: $s4;
		}
		&__link {
			color: $colorWhite;
		}

		// STATEs
		.menu-opened &__tool {
			&-icon {
				&::before,
				&::after {
					transition-delay: 0s, 0s;
					box-shadow: none;
					background: $colorPrimary;
				}
				&::before {
					transform: rotate(45deg);
				}
				&::after {
					transform: rotate(-45deg);
				}
			}
		}
	}
	@media ($lgUp) {
		&__list {
			flex-grow: 1;
			font-size: 16px;
			line-height: (19/16);
			display: flex;
			white-space: nowrap;
		}
		&__item + &__item {
			margin-left: $s6;
		}
		&__link {
			color: $colorBlack;
		}
	}
}
